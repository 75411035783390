export default function () {
    return {
        isWishesOpen: false,
        isWishesModalOpen: false,
        activeTextareaRef: null,
        categories: [{
            wishes: [
                {
                    description: "Ładowanie życzeń..."
                }
            ]
        }],
        selectedCategoryIndex: 0,
        windowWidth: null,

        init() {
            const $this = this;

            this.getWishes()

            this.windowWidth = window.outerWidth;

            window.onresize = function() {
                $this.windowWidth = window.outerWidth;
            };
        },

        get selectedCategory() {
            return this.categories[this.selectedCategoryIndex]
        },
        
        insertWish(index) {
            this.$refs[this.activeTextareaRef].value = this.selectedCategory.wishes[index].description;
            this.isWishesModalOpen = false;
        },

        setCategory(index) {
            this.$refs.wishes.scrollTo({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
            });          
            this.$refs.wishes_mobile.scrollTo({
                top: 0, 
                left: 0, 
                behavior: 'smooth'
            });
            this.selectedCategoryIndex = index
        },
        
        getWishes() {
            const $this = this;

            fetch('/module/_incart_customization/Wishes?ajax=true', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            })
            .then(response => response.json())
            .then((result) => {
                $this.categories = result.categories
            })
        },
         
        isMobile() {
            return this.windowWidth < 768;
        },

        openWishesModal(ref) {
            this.activeTextareaRef = ref;
            this.isWishesModalOpen = !this.isWishesModalOpen;
        },

        storeWish(event) {
            let form = $(event.target).closest('._incart_customization_form')[0];
            const $this = this;
            const formData = new FormData(form);
    
            $.ajax({
                type: "POST",
                url: form.attributes.action.value,
                processData: false,
                contentType: false,
                data: formData,
                
                success(res){
                    let resData = JSON.parse(res);

                    let $productCustomizationFormSection = $(form).closest('.product-customization-form-section')[0];
                    let $productCustomizationButton = $('._incart_customization_button_'+resData.key);

                    $($productCustomizationFormSection).replaceWith(resData._incart_customization_single);
                    $($productCustomizationButton).replaceWith(resData._incart_customization_button);
                    
                    $this.isWishesOpen = false;

                    alert("Życzenia zostały zapisane");
                },
            });
        }
    }
}