import wishes from './alpinejs/components/wishes';

window.alpinejsComponets = {
    wishes: wishes
}

const inCartCustomization = {
    init() {
        const forms =  $('._incart_customization_form');
        const $this = this;

        forms.each(function(index, form){
            $this.initFormObserver(form);
        });
    },
    
    initFormObserver(form) {
        const $this = this;
        
        $(form).unbind('submit');

        $(form).on('submit', function(e){
            e.preventDefault();
            
            const formData = new FormData(form);
    
            $.ajax({
                type: "POST",
                url: form.attributes.action.value,
                processData: false,
                contentType: false,
                data: formData,
                
                success(res){
                    let resData = JSON.parse(res);

                    let $productCustomizationFormSection = $(form).closest('.product-customization-form-section')[0];

                    $($productCustomizationFormSection).replaceWith(resData._incart_customization_single);
    
                    alert("Życzenia zostały zapisane");
                    $this.init();            
                },
            });
        });
    }
}

$(document).ready(() => {
    inCartCustomization.init();
});